<template>
  <div>
    <main>
      <Hero />

      <section
        id="prez"
        ref="prez"
      >
        <b-container class="mt-sm-n5 py-5">
          <b-row class="mx-0">
            <b-col
              class="mx-auto text-center"
              lg="8"
            >
              <h1 class="mb-3">
                <img
                  alt="Mobilizon"
                  height="45"
                  :src="`${$t('baseurl')}img/logo-mbz-purple.svg`"
                />
              </h1>
              <h2 class="mt-4 mb-2">
                <span
                  v-for="(tag, index) in $t('home.intro.tags')"
                  :key="index"
                >
                  {{ tag }}
                  <span v-if="index < $t('home.intro.tags').length - 1"> ⋅ </span>
                </span>
              </h2>
              <img
                alt=""
                class="my-4"
                :src="`${$t('baseurl')}img/rose.jpg`"
              />
            </b-col>
          </b-row>
          <b-row class="mx-0">
            <b-col
              lg="4"
              class="py-4"
            >
              <h3>
                <i class="fas fa-screwdriver-wrench"></i>
                <span v-html="$t('home.prez.tool.title')"></span>
              </h3>
              <div v-html="$t('home.prez.tool.md')"></div>
            </b-col>
            <b-col
              lg="4"
              class="py-4"
            >
              <h3>
                <i class="fas fa-spa"></i>
                <span v-html="$t('home.prez.alt.title')"></span>
              </h3>
              <div v-html="$t('home.prez.alt.md')"></div>
            </b-col>
            <b-col
              lg="4"
              class="py-4"
            >
              <h3>
                <i class="fac fa-fediverse"></i>
                <span v-html="$t('home.prez.soft.title')"></span>
              </h3>
              <div v-html="$t('home.prez.soft.md')"></div>
            </b-col>
          </b-row>
          <b-row
            align-h="around"
            class="mx-auto my-4"
            style="max-width: 800px"
          >
            <b-col lg="5">
              <b-button
                block
                href="#what-is-mobilizon"
                size="lg"
                variant="primary my-2"
                @click.prevent="$refs['what-is-mobilizon'].scrollIntoView({ behavior: 'smooth' });"
                v-html="$t('home.prez.btn[0]')"
              />
            </b-col>
            <b-col lg="5">
              <b-button
                block
                href="https://mobilizon.org"
                size="lg"
                variant="primary my-2"
                v-html="$t('home.prez.btn[1]')"
              />
            </b-col>
          </b-row>
          <b-row class="mx-0">
            <b-col
              class="mx-auto text-center"
              lg="11"
            >
              <i class="fas fa-camera fa-shape-circle"></i>
              <b-img
                alt=""
                class="my-4 img-thumbnail"
                fluid
                :src="`${$t('baseurl')}img/${$t('lang')}/event-tree-participation-rose-utopia.jpg`"
              />
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section
        id="what-is-mobilizon"
        ref="what-is-mobilizon"
      >
        <b-container class="py-5">
          <b-row class="mx-0">
            <b-col
              lg="8"
              class="mx-auto"
            >
              <div v-html="$t('home.what.intro')"></div>

              <h3 class="mt-4">
                <i class="fas fa-calendar-day"></i>
                <span v-html="$t('home.what.event.title')"></span>
              </h3>
              <div
                v-html="$t('home.what.event.md')"
              ></div>
              <b-img
                alt=""
                class="my-3"
                fluid
                :src="`${$t('baseurl')}img/event-creation.jpg`"
              />

              <h3 class="mt-4">
                <i class="fas fa-masks-theater"></i>
                <span v-html="$t('home.what.profile.title')"></span>
              </h3>
              <div v-html="$t('home.what.profile.md')"></div>

              <i class="fas fa-camera fa-shape-circle"></i>
              <b-img
                alt=""
                class="my-4 p-2 img-thumbnail"
                fluid
                :src="`${$t('baseurl')}img/${$t('lang')}/rose-boreal-settings-filled.png`"
              />

              <h3 class="mt-4">
                <i class="fas fa-users"></i>
                <span v-html="$t('home.what.group.title')"></span>
              </h3>
              <div v-html="$t('home.what.group.md')"></div>

              <b-img
                alt=""
                class="mt-3"
                fluid
                :src="`${$t('baseurl')}img/group.jpg`"
              />
            </b-col>
          </b-row>
        </b-container>
      </section>

      <Quote />

      <Design />

      <Who />

      <sfs
        :offpay="false"
        :oto="true"
      />
    </main>
  </div>
</template>

<script>
import Hero from './home/Hero.vue';
import Quote from './home/Quote.vue';
import Design from './home/Design.vue';
import Who from './home/Who.vue';

import sfs from '../../commons/components/sfs/Main.vue';

export default {
  components: {
    Hero,
    Quote,
    Design,
    Who,
    sfs,
  },
};
</script>
