<template>
  <header id="header">
    <b-container>
      <b-row
        align-h="between"
        align-v="center"
      >
        <b-col md="5">
          <h1>
            <router-link :to="`/${$t('lang')}/`">
              <img
                alt="Mobilizon"
                height="45"
                :src="`${$t('baseurl')}img/logo-mbz.svg`"
              />
            </router-link>
          </h1>
          <p
            class="devby"
            v-html="$t('home.devby')"
          ></p>
        </b-col>
        <b-col md="7">
          <b-nav
            class="float-right list-unstyled"
            pills
          >
            <b-nav-item :to="`/${$t('lang')}/news`">
              {{ $t('menu.news') }}
              <b-badge
                pill
                variant="light"
                v-text="Object.keys($t('news.posts')).length"
              />
            </b-nav-item>
            <b-nav-item :to="`/${$t('lang')}/faq`">
              {{ $t('menu.faq') }}
            </b-nav-item>
            <b-nav-item :href="$t('git.mobilizon')">
              Git
            </b-nav-item>
            <!--
            <b-nav-item :to="`/${$t('lang')}/medias`">
              {{ $t('menu.medias') }}
            </b-nav-item>
            <b-nav-item :to="`/${$t('lang')}/hall-of-fame`">
              {{ $t('menu.hall-of-fame') }}
            </b-nav-item>
            -->
            <b-nav-text
              class="nav-item"
              tag="li"
            >
              <I18n />
            </b-nav-text>
          </b-nav>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
#header {
  background: var(--j-p2);
  border-bottom: 2px solid var(--j-d2);
  color: var(--j-s5);

  .container {
    background: transparent;
    margin-bottom: 0;

    a,
    div.btn-group button,
    a.dropdown-item a {
      color: var(--j-s5);
      text-decoration: none;
      border-radius: 0;

      &:hover {
        color: var(--j-s6);
      }
    }

    ul {
      justify-content: space-around;

      div.btn-group {
        flex: 1 1 auto;
        text-align: center;
      }

      .nav-item {
        & > a {
          &:after {
            display:block;
            content: '';
            border-bottom: 5px solid #fff;
            padding-bottom: 8px;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
          }
          &:hover:after, &:focus:after { transform: scaleX(1); }
        }
      }
      .navbar-text {
        padding: 0;
      }
    }

    & > div {
      align-items: center;
    }

    h2 {
      font-size: 1.5em;
    }
  }

  .btn {
    border: none;
    border-bottom: 5px solid transparent;
    color: var(--j-s5);
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover, &:focus {
      background: transparent;
      border-bottom: 5px solid #fff;
      color: var(--j-s6);
    }
  }
  .show > .btn {
    background: transparent;
    border-bottom: 5px solid #fff;
    color: var(--j-s6);
  }
}
</style>
